import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";

const QAndAWrapper = styled.div`
  margin: 10px 200px;
`;

const NameSubT = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: green;
`;

const NameSub = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: darkgreen;
`;

const NameSubA = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: darkgreen;
`;

export default function QAndA() {
  return (
    <QAndAWrapper>
      <NameSubT>FAQ</NameSubT>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <NameSub>Q: Why should I invest in Sad Cat Thumb Up Coin?</NameSub>
        </AccordionSummary>
        <AccordionDetails>
          <NameSubA>
            A: Because nothing says "financial security" quite like a sad cat
            reluctantly giving you a thumbs up.
          </NameSubA>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <NameSub>
            Q: What's the unique selling point of Sad Cat Thumb Up Coin?
          </NameSub>
        </AccordionSummary>
        <AccordionDetails>
          <NameSubA>
            A: It's the only coin guaranteed to make you question every
            financial decision you've ever made while simultaneously feeling
            strangely encouraged by a melancholic feline.
          </NameSubA>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <NameSub> Q: Wen Moon?</NameSub>
        </AccordionSummary>
        <AccordionDetails>
          <NameSubA>A: Very Soon!</NameSubA>
        </AccordionDetails>
      </Accordion>
    </QAndAWrapper>
  );
}
