import React from "react";
import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
import logo from "../logo.png";
import logo2 from "../logo2.png";
import ds from "../ds.png";
import dt from "../dt.svg";
import x from "../x.png";
import t from "../t.webp";
import "../App.css";
import QAndA from "./q-and-a";

const bounceAnimation = keyframes`${fadeIn}`;

const Name = styled.div`
  font-size: 40px;
  margin: 20px;
  font-weight: 800;
  color: green;
`;

const BouncyDiv = styled.div`
  animation: 20s ${bounceAnimation};
  font-size: 35px;
  font-weight: 600;
  color: green;
`;

const NameSub = styled.div`
  font-size: 20px;
  margin: 20px;
  font-weight: 800;
  color: purple;
`;

// const Address = styled.div`
//   color: red;
//   font-size: 20px;
//   margin: 20px;
//   font-weight: 800;
// `;

const Title = "Thumbs Up Cat";
const Symbol = "THUMBCAT";

const address = "BFNZxEMwg99EB72BUhZ9NfFcVyLM5GyrRRYnkuz3uuJj";

const ThumbCat = () => {
  return (
    <div>
      <Name>{Title}</Name>

      <BouncyDiv>
        ${Symbol} ${Symbol} ${Symbol} ${Symbol} ${Symbol} ${Symbol}
      </BouncyDiv>

      <div>
        <img className="logo" alt="main logo" src={logo} />
      </div>
      <NameSub>
        When life gives you lemons, just imagine a sad cat giving you a thumbs
        up.
        <br /> Because why not invest in emotions, right? 🐾 👍
      </NameSub>
      {/* <Address>CA: {address}</Address> */}
      <div>
        <img className="logo2" alt="2nd logo" src={logo2} />
        <img className="logo2" alt="2nd logo" src={logo2} />
        <img className="logo2" alt="2nd logo" src={logo2} />
      </div>
      <QAndA />
      <div>
        <a href="https://twitter.com/thumbsupcat_sol">
          <img className="icon" src={x} alt="twitter" />
        </a>
        <a href="https://t.me/thumbsupcatcoin">
          <img className="icon" src={t} alt="telegram" />
        </a>
        <a href={`https://dexscreener.com/solana/${address}`}>
          <img className="icon" src={ds} alt="dexscreen" />
        </a>
        <a href={`https://dexscreener.com/solana/${address}`}>
          <img className="dt" src={dt} alt="dextools" />
        </a>
      </div>
    </div>
  );
};

export default ThumbCat;
