import "./App.css";
import ThumbCat from "./thumbcat/ThumbCat";

function App() {
  return (
    <div className="App">
      <ThumbCat />
    </div>
  );
}

export default App;
